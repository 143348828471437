import React from 'react';

import Link from 'utils/localized-link';

import Banner from 'components/sections/banner';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Separator from 'components/separator';
import RobotContent from '../components/sections/robot-content';
import HowItWorksRobot from '../components/sections/how-it-works-robot';

import HowItWorksConcierge from '../components/sections/how-it-works-concierge';

const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const bannerButtons = [
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts?subject=Robot'
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'works.banner.buttons.contacts'
  }
];

const bannerImage = 'home/robot-banner-bottom2.jpg';

const RobotPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="robot.title" />
    <RobotContent />
    <HowItWorksRobot />
    <HowItWorksConcierge />
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      text="robot.banner.text"
      title="robot.banner.title"
    />
  </Layout>
);

export default RobotPage;
