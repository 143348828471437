import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@youship/components/objects/button';
import Benefits from 'components/sections/benefits';

import Img from 'components/image';
import ClockIcon from 'images/icons/clock.inline.svg';
import DollarSignIcon from 'images/icons/dollar-sign.inline.svg';
import MultiCheckboxIcon from 'images/icons/multi-checkbox.inline.svg';
import TreeIcon from 'images/icons/tree.inline.svg';
import video1 from 'images/robot/robot.mp4';

import './styles.scss';

const BENEFITS = [
  {
    title: 'robot.benefits.b1.title',
    description: 'robot.benefits.b1.description',
    icon: DollarSignIcon
  },
  {
    title: 'robot.benefits.b2.title',
    description: 'robot.benefits.b2.description',
    icon: ClockIcon
  },
  {
    title: 'robot.benefits.b3.title',
    description: 'robot.benefits.b3.description',
    icon: TreeIcon
  }
];

const headerImage1 = 'home/robot.png';
const headerImage2 = 'home/robot-banner.png';
const videoPreview = 'home/robot-banner-bottom2.jpg';

const RobotContent = () => {
  const intl = useIntl();

  return (
    <>
    <div className="robot-header">
      <div className="container">
        <div className="row robot-header__row d-flex align-items-center">
          <div className="col col-12 col-md-6 offset-md-1 order-md-3">
            <div className="robot-header__text">
              <h1 className="h1--display robot-header__title">
                <FormattedMessage id="home.concierge.title" />
              </h1>
              <div className="robot-header__description">
                <FormattedMessage id="home.concierge.description" />
              </div>
            </div>
          </div>
          {/* <div className="col col-10 col-md-4 col-xl-4 order-md-1"> */}
          <div className="col col-10 offset-0 col-md-5 col-xl-5 order-md-1">
            <div className="d-none d-md-block robot-header__background-element" />
              <Img
                alt="Header 1"
                classNames="robot-header__image"
                src={headerImage1}
              />
          </div>
          {/*  <div className="col col-10 offset-2 col-md-3 offset-md-2 col-xl-3 offset-xl-2 order-md-2 robot-header__image-column">
            <Img
              alt="Header 2"
              classNames="robot-header__image robot-header__image--small"
              src={headerImage2}
            />
          </div>  */}
        </div>
      </div>
    </div>

    <Benefits
      benefits={BENEFITS}
      title="robot.benefits.title"
    />

    <div className="robot-benefits">
      <div className="container">
        <div className="robot-benefits__header">
          <div className="row justify-content-center">
            <div className="col col-12 col-md-10">
              <h2 className="h2--display robot-benefits__title">
                <FormattedMessage id="robot.benefits.subtitle" />
              </h2>
              <div className="font-style-body-large robot-benefits__description">
                <FormattedMessage id="robot.benefits.description" />
              </div>
            </div>
          </div>
        </div>
       {/*  <div>
            <Img
              alt="Header 2"
              classNames="robot-benefits__image"
              src={headerImage2}
            />
        </div> */}
        <div>
            <video autoplay="true" loop="true" muted="true" controls="true" poster={videoPreview} className="robot-benefits__video">
              <source src={video1} />
            </video>
        </div>
    </div>
  </div>

  </>
  );
};

export default RobotContent;
