import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'utils/localized-link';

import Button from '@youship/components/objects/button';
import PhoneStepsSlider from 'components/phone-steps-sliders';

import stepSlide1Image from 'images/robot/concierge-home.png';
import stepSlide2Image from 'images/robot/concierge-new.png';
import stepSlide3Image from 'images/robot/concierge-shipment.png';
import stepSlide4Image from 'images/robot/concierge-tracking.png';
import botImage from 'images/robot/concierge-bot.png';

import './styles.scss';

const STEPS_SLIDES = [
  {
    index: 1,
    title: 'robot.concierge.s1.title',
    description: 'robot.concierge.s1.description'
  },
  {
    index: 2,
    title: 'robot.concierge.s2.title',
    description: 'robot.concierge.s2.description'
  },
  {
    index: 3,
    title: 'robot.concierge.s3.title',
    description: 'robot.concierge.s3.description'
  },
  {
    index: 4,
    title: 'robot.concierge.s4.title',
    description: 'robot.concierge.s4.description'
  }
];

const PHONE_SLIDES = [
  stepSlide1Image,
  stepSlide2Image,
  stepSlide3Image,
  stepSlide4Image,
];

const HowItWorksConcierge = () => {
  const intl = useIntl();
  const stepsSlides = STEPS_SLIDES;
  const phoneSlides = PHONE_SLIDES;

  return (
    <div className="how-it-works-concierge">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col col-12 col-md-6">
            <h6 className="how-it-works-concierge__title">
              <FormattedMessage id="robot.concierge.title" />
            </h6>
            <h2 className="h2--display how-it-works-concierge__description">
              <FormattedMessage id="robot.concierge.subtitle" />
            </h2>
          </div>
        </div>
        <PhoneStepsSlider
          invert
          light
          phoneSlides={phoneSlides}
          stepsSlides={stepsSlides}
        >
          <div className="how-it-works-concierge__action">
            <div className="how-it-works-concierge__action-title">
              <FormattedMessage id="robot.concierge.action.title" />
            </div>
            <div className="how-it-works-concierge__action-description">
              <FormattedMessage id="robot.concierge.action.description" />
            </div>
            <Button
              linkComponent={Link}
              linkProps={{ to: '/contacts?subject=Robot' }}
              noArrow
              noNewTab
              outline
              small
              text={intl.formatMessage({ id: 'robot.concierge.action.btn' })}
            />
          </div>
        </PhoneStepsSlider>

        {/* <div className="how-it-works-concierge__separator" />

        <div className="row">
          <div className="col col-12 col-lg-8 col-xl-6 offset-0 offset-lg-0 ys-center">
            <img
                  src={botImage}
                  alt="Robot"
                  className='how-it-works-concierge__track-image'
                />
          </div>
          <div className="col col-12 col-lg-8 col-xl-6 offset-0 offset-lg-0">
            <div className="how-it-works-concierge__track-title">
              <FormattedMessage id="robot.concierge.track.title" />
            </div>
            <div className="how-it-works-concierge__track-description">
              <FormattedMessage id="robot.concierge.track.subtitle" />
            </div>
          </div>
        </div> */}

      </div>
    </div>
  );
};

export default HowItWorksConcierge;
