import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Separator = ({ classNames, light }) => (
  <div className={`separator${classNames ? ` ${classNames}` : ''}`}>
    <div className="d-block d-md-none container">
      <div className={`separator__line${light ? ' separator__line--light' : ''}`} />
    </div>
    <div className={`d-none d-md-block separator__line${light ? ' separator__line--light' : ''}`} />
  </div>
);

Separator.propTypes = {
  classNames: PropTypes.string,
  light: PropTypes.bool
};

Separator.defaultProps = {
  classNames: null,
  light: null
};

export default Separator;
