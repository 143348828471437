import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BenefitItem from 'components/benefit-item';

import illustration1 from 'images/be-carrier/benefits_illustration_1.svg';
import illustration2 from 'images/be-carrier/benefits_illustration_2.svg';

import './styles.scss';

const Benefits = ({ alignTextLeft, benefits, description, title }) => (
  <div className="benefits">
    <div className="container">
      {(title || description) && (
        <div className={`benefits__header${alignTextLeft ? ' benefits__header--align-left' : ''}${title && description ? ' benefits__header--large-margin' : ''}`}>
          <div className="row justify-content-center">
            <div className="col col-12 col-md-6">
              {title && (
                <h2 className={`h2--display benefits__title${description ? ' benefits__title--margin' : ''}`}>
                  <FormattedMessage id={title} />
                </h2>
              )}
              {description && (
                <div className="font-style-body-large">
                  <FormattedMessage id={description} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {Array.isArray(benefits) && (
        <div className="row justify-content-center">
          <div className="col col-12 col-lg-12">
            <div className="row justify-content-center">
              {benefits.map((item, index) => (
                <div
                  key={index}
                  className="col col-12 col-md-3 benefits__item-wrapper"
                >
                  <BenefitItem
                    description={item.description}
                    icon={item.icon ? <item.icon/> : null }
                    title={item.title}
                    titleIsLarge
                  />
                  {(index < benefits.length - 1) && (
                    <img
                      alt="Benefit Illustration"
                      className="benefits__illustration d-none d-md-block"
                      src={index%2 === 0 ? illustration1 : illustration2}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

Benefits.propTypes = {
  alignTextLeft: PropTypes.bool,
  benefits: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.func
  })),
  description: PropTypes.string,
  title: PropTypes.string
};

Benefits.defaultProps = {
  alignTextLeft: false,
  benefits: null,
  description: null,
  title: null
};

export default Benefits;
