import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@youship/components/objects/button';

import PhoneStepsSlider from 'components/phone-steps-sliders';

import stepSlide1Image from 'images/how-it-works/stores-list-restaurants.png';
import stepSlide2Image from 'images/how-it-works/stores-ordering.png';
import stepSlide3Image from 'images/how-it-works/stores-payment.png';
import stepSlide4Image from 'images/robot/robot-tracking.png';

import './styles.scss';

const STEPS_SLIDES = [
  {
    index: 1,
    title: 'robot.receive.s1.title',
    description: 'robot.receive.s1.description'
  },
  {
    index: 2,
    title: 'robot.receive.s2.title',
    description: 'robot.receive.s2.description'
  },
  {
    index: 3,
    title: 'robot.receive.s3.title',
    description: 'robot.receive.s3.description'
  },
  {
    index: 4,
    title: 'robot.receive.s4.title',
    description: 'robot.receive.s4.description'
  }
];

const PHONE_SLIDES = [
  stepSlide1Image,
  stepSlide2Image,
  stepSlide3Image,
  stepSlide4Image
];

const HowItWorksRobot = () => {
  const intl = useIntl();
  const stepsSlides = STEPS_SLIDES;
  const phoneSlides = PHONE_SLIDES;

  return (
    <div className="how-it-works-robot">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col col-12 col-md-8">
            <h6 className="how-it-works-robot__title">
              <FormattedMessage id="robot.receive.title" />
            </h6>
            <h2 className="h2--display how-it-works-robot__description">
              <FormattedMessage id="robot.receive.subtitle" />
            </h2>
          </div>
        </div>
        <PhoneStepsSlider
          phoneSlides={phoneSlides}
          stepsSlides={stepsSlides}
        >
          <div className="how-it-works-robot__action">
            <div className="how-it-works-robot__action-title">
              <FormattedMessage id="robot.receive.action.title" />
            </div>
            <div className="how-it-works-robot__action-description">
              <FormattedMessage id="robot.receive.action.description" />
            </div>
          </div>
        </PhoneStepsSlider>
        
        <div className="how-it-works-robot__separator" />

        <div className="row">
          <div className="col col-12 col-lg-7 col-xl-5 offset-1 offset-lg-1">
            <div className="how-it-works-robot__track-title">
              <FormattedMessage id="robot.receive.track.title" />
            </div>
            <div className="how-it-works-robot__track-description">
              <FormattedMessage id="robot.receive.track.subtitle" />
            </div>
          </div>
          <div className="col col-12 col-lg-8 col-xl-6 offset-0 offset-lg-0 ys-center">
           <img
                  src={stepSlide4Image}
                  alt="Track robot"
                  className='how-it-works-robot__track-image'
                />
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default HowItWorksRobot;
